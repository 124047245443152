import React, { useEffect } from "react";
import { Globals } from "react-spring";
import { BaseLayout, useReduceMotion } from "gatsby-theme-jqi";
import useHeaderMenu from "../hooks/use-header-menu";
import useFooterMenu from "../hooks/use-footer-menu";
 
const Provider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const headerProps = { menuItems: useHeaderMenu() } as JQI.SiteHeaderProps;
  const footerProps = { menuItems: useFooterMenu() } as JQI.SiteFooterProps;
  // Disable React Spring animations based on user's OS-level reduced motion preference.
  const prefersReducedMotion = useReduceMotion() as boolean;
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion]);
  return (
    <BaseLayout headerProps={headerProps} footerProps={footerProps}>
      {children}
    </BaseLayout>
  );
};

export default Provider;